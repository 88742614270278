<div class="d-flex justify-content-between top-nav-container large-nav">
  <div class="logo-container">
    <a routerLink="/" style="margin: auto">
      <img class="full-logo" src="/assets/images/logos/chatperk-logo.svg"/>
    </a>
    <div class="spaces-dropdown">
      <app-space-dropdown></app-space-dropdown>
    </div>
  </div>

  <div class="actions-container">

    <!-- help -->
    <div class="help-icon-container">
            <span class="help-icon">
                <sxw-svg-icon name="lifebuoy" width="20" height="20"></sxw-svg-icon>
            </span>
      <span class="help-text">Help</span>
    </div>

    <ng-container *ngTemplateOutlet="userMenu"></ng-container>

  </div>

</div>
<div class="d-flex justify-content-between top-nav-container small-nav">
  <div class="sidenav-trigger-container">
    <div (click)="showSideNav()" class="side-nav-trigger"><sxw-svg-icon name="menu-lines"></sxw-svg-icon></div>
  </div>
  <div class="logo-container">
    <a routerLink="/">
      <img class="small-logo" src="/assets/images/logos/chatperk-logo.webp"/>
    </a>
  </div>
  <div class="actions-container">
    <ng-container *ngTemplateOutlet="userMenu"></ng-container>
  </div>
</div>


<ng-template #userMenu>
  <div class="user-menu-icon" (click)="toggleMenu()">
    <div class="default-image">
      <div class="user-initials">
        {{ authenticationService.user$.value?.name?.[0] }}{{ authenticationService.user$.value?.surname?.[0] }}
      </div>
    </div>
  </div>
  <div class="user-menu" *ngIf="showMenu">
    <div class="user-menu-container">
      <div class="user-info">
        <div class="user-menu-icon">
          <div class="default-image">
            <div class="user-initials">
              {{ authenticationService.user$.value?.name?.[0] }}{{ authenticationService.user$.value?.surname?.[0] }}
            </div>
          </div>
        </div>
        <div>
          <p> {{ authenticationService.user$.value?.fullName }} </p>
          <p style="color:#515151;font-size:12px;">{{ authenticationService.user$.value?.email }} </p>
        </div>
      </div>

      <div class="user-actions">
        <div class="user-action">
          <a href="/account">
            <sxw-svg-icon name="user" width="20" height="20" class="icon"></sxw-svg-icon>
            <span>Account settings</span>
          </a>
        </div>

        <div class="user-action">
          <a (click)="logout()">
            <sxw-svg-icon name="exit" width="20" height="20" class="icon"></sxw-svg-icon>
            <span> Logout </span>
          </a>
        </div>
      </div>

      <div class="menu-footer">
        <div class="link" (click)="navigateToUrl(termsUrl)">Terms & conditions</div>
        <div class="dot"></div>
        <div class="link" (click)="navigateToUrl(privacyUrl)">Privacy Policy</div>
      </div>
    </div>

  </div>
</ng-template>
