<sxw-popup-wrapper></sxw-popup-wrapper>
<div class="account-settings">
    <!-- Title and Save Button -->
    <div class="header">
        <h2>{{"general.title" | translate}}</h2>
        <button class="save-button" (click)="formElement.submit()">{{"actions.saveChanges" | translate}}</button>
    </div>

    <!-- Account Section -->
    <div class="section">
        <div class="section-header">
            <h3 class="section-label">{{"general.account" | translate}}</h3>
        </div>
        <div class="section-content">
            <sxw-form
                [formGroup]="formModel.formGroup"
                [formatData]="formatFormData"
                [mutationConfig]="mutationConfig"
                (onMutationComplete)="handleMutationComplete($event)"
                (onMutationError)="hundleMutatuonError($event)"
                #formElement>
                <div class="form-group">
                    <div class="inputs-row removeme">
                        <div class="input-group">
                            <label class="input-label">{{"general.name" | translate}}</label>
                                <sxw-text-input
                                id="name"
                                placeHolder="{{'general.namePlaceHolder' | translate}}" formControlName="name"
                                [validations]="{isNotEmpty:{}}"></sxw-text-input>
                        </div>
                        <div class="input-group">
                            <label class="input-label">{{"general.familyName" | translate}}</label>
                                <sxw-text-input
                                id="name"
                                placeHolder="{{'general.namePlaceHolder' | translate}}" formControlName="surname"
                                [validations]="{isNotEmpty:{}}"></sxw-text-input>
                        </div>
                    </div>
                </div>
            </sxw-form>
            <div class="info-group">
                <div>
                    <label>{{"general.email" | translate}}</label>
                    <p class="subtitle">{{ user.email }}</p>
                </div>
                <a class="edit-button"  [routerLink]="[{ outlets: { popups: ['email'] } }]"><i class="pi pi-pencil icon-style"></i></a>
            </div>
            <hr>

            <div class="info-group">
                <div>
                    <label>{{"general.mobileNumber" | translate}}</label>
                    <p class="subtitle">{{ user.mobile }}</p>
                </div>
                <a class="edit-button" [routerLink]="[{ outlets: { popups: ['mobile'] } }]"><i class="pi pi-pencil icon-style"></i></a>
            </div>
            <hr>
        </div>
    </div>

    <!-- Access Section -->
    <div class="section">
        <div class="section-header">
            <h3 class="section-label">{{"general.access" | translate}}</h3>
        </div>
        <div class="section-content">
            <div class="info-group">
                <div>
                    <label>{{"general.changePassword" | translate}}</label>
                    <p class="subtitle">{{"general.changePasswordSubtitle" | translate}}</p>
                </div>
                <a class="change-button" [routerLink]="[{ outlets: { popups: ['reset-password'] } }]">{{"actions.change" | translate}}</a>
            </div>
            <hr>
            <div class="info-group">
                <div>
                    <label>{{"general.twoFactorAuthentication" | translate}}</label>
                    <p class="subtitle">{{"general.twoFactorAuthenticationSubtitle" | translate}}</p>
                </div>
                <div class="">

                    <sxw-radio-input
                        #switch2FA
                        theme="primary-theem"
                        [disabled]="!user.hasVerifiedMobile"
                        [value]="user.is2FAEnabled"
                        (change)="toggle2FA($event)"
                    >
                    </sxw-radio-input>
                </div>
            </div>
            <hr>
        </div>
    </div>

    <!-- Danger Zone -->
    <div class="section danger-zone">
        <div class="section-header">
            <h3 class="section-label">{{"general.dangerZone" | translate}}</h3>
        </div>
        <div class="section-content">
            <div class="info-group">
                <div>
                    <label>{{"general.deleteAccount" | translate}}</label>
                    <p class="subtitle">{{"general.deleteAccountSubtitle" | translate}}</p>
                </div>
                <sxw-button label='{{"actions.delete" | translate}}'></sxw-button>
            </div>
        </div>
    </div>
</div>
