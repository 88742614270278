import {Component, ElementRef, ViewChild} from '@angular/core';
import { ISideNavConfig} from "src/app/layout/sidenav/interfaces/ISideNavConfig";
import {sideNavConfig} from "../../../config/side-nav.config";
import {AuthService} from "../../../chatperk-core/services/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {SideNavService} from "../../sidenav/services/side-nav.service";

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss']
})
export class DashboardLayoutComponent {
  @ViewChild('content') contentEleRef?: ElementRef<HTMLDivElement>;

  sideNavConfig = sideNavConfig(this.authService.getUser());

  constructor(
    public authService: AuthService,
    activatedRoute: ActivatedRoute,
    sideNavService: SideNavService
  ) {
    activatedRoute.data.subscribe(data => sideNavService.setVisibility(data['sidenav']))
  }

  scrollTo(options?: ScrollToOptions) {
    this.contentEleRef?.nativeElement?.scrollTo(options);
  }
}
