import {ISideNavConfig} from "../layout/sidenav/interfaces/ISideNavConfig";
import {AuthUserModel} from "../chatperk-core/models/AuthUser.model";

export const sideNavConfig = (user?: AuthUserModel): ISideNavConfig => ({
  items: [
    {
      title: '',
      items: [
        {
          label: 'sidenav.assistant',
          icon: 'chat-assistant',
          routerLink: {
            path: [
              `/${user?.activeSpace?.code}`,
              'assistants',
            ],
          },
          default: true,
        },
        {
          label: 'sidenav.analytics',
          icon: 'bar-chart',
          soon: true,
          routerLink: { path: '/dashboard/statistics' },
        },
        {
          label: 'sidenav.settings',
          icon: 'settings',
          routerLink: { path: '/space/settings' },
        },
      ],
    },
  ],
});
