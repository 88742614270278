import {Component, OnInit, inject, HostListener, ElementRef} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/chatperk-core/services/auth.service';
import { environment } from 'src/environments/environment';
import {SideNavService} from "../../../sidenav/services/side-nav.service";


@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss'],

})
export class TopNavComponent implements OnInit {

  showMenu: boolean = false;
  authenticationService = inject(AuthService);
  router = inject(Router);
  termsUrl = `${environment.website_url}/terms_of_service`
  privacyUrl = `${environment.website_url}/privacy_policy`

  constructor(private elementRef: ElementRef, public sideNavService: SideNavService) {
  }

  ngOnInit() {
  }

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: HTMLElement): void {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.showMenu = false ;
    }
  }

  logout(){
    this.authenticationService.logout();
    this.router.navigateByUrl('/auth/login');
  }

  navigateToUrl(url: string){
    window.location.href = url;
  }

  showSideNav() {
    this.sideNavService.expand();
  }
}


