import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SideNavService {

  $expanded = new BehaviorSubject<boolean>(false);
  $visible = new BehaviorSubject<boolean>(false);

  constructor() {}

  expand() {
    this.$expanded.next(true);
  }

  collapse() {
    if (!this.$expanded.value) {
      return ;
    }
    this.$expanded.next(false);
  }

  setVisibility(state: boolean) {
    this.$visible.next(state);
  }
}
