<div class="space-dropdown-container">
    <button class="space-dropdown-toggle" (click)="toggleDropdown()">
        <img src="assets/images/icons/space-icon.svg" alt="Space Icon" class="spaceIcon" />
        <span class="space-name" title="{{ selectedSpace?.name }}">{{ selectedSpace?.name || 'Select Space' }}</span>
        <div class="space-dropdown-arrows">
            <img class="space-dropdown-arrows-img" src="assets/images/icons/drop-down-arrows.svg" alt="Dropdown Arrow" />
        </div>
    </button>

    <div *ngIf="showDropdown" class="space-dropdown-menu">
      <input type="text" [(ngModel)]="searchQuery" (ngModelChange)="onInputChanged($event)" class="dropdown-search"  placeholder="Search space..." />

      <div class="dropdown-items-container"
        infiniteScroll
        (scrolled)="onScroll()"
        [scrollWindow]="false"
        [infiniteScrollThrottle]="throttle"
        [infiniteScrollDistance]="scrollDistance" >
          <!-- <p *ngFor="let space of spaces" class="dropdown-item" [ngClass]="{'selected':selectedSpace?.id == space.id }"  (click)="selectSpace(space)">
            {{ space.name }}
            <img *ngIf="selectedSpace?.id == space.id" class="selected-space" src="assets/images/icons/selected-space-icon.svg">
          </p> -->
          <div *ngFor="let space of spaces" class="dropdown-item" [ngClass]="{'selected':selectedSpace?.id == space.id }" (click)="selectSpace(space)">
            <div class="dropdown-item-content">
              <span class="space-text">{{ space.name }}</span>
              <img *ngIf="selectedSpace?.id == space.id" class="selected-space" src="assets/images/icons/selected-space-icon.svg">
            </div>
          </div>
          <div *ngIf="loading">
            <div class="skeleton"></div>
            <div class="skeleton"></div>
          </div>
      </div>
      <div class="empty-list-item" *ngIf="!spaces.length && !loading"><span class="space-text">No Spaces Available</span></div>

      <a [routerLink]="['/space/add']" class="create-space-btn">Create Space</a>
    </div>
</div>
