<div class="side_nav_container" [ngClass]="{'expanded': sideNavService.$expanded | async, 'visible': sideNavService.$visible | async}">
    <div class="spaces_dropdown">
      <app-space-dropdown></app-space-dropdown>
    </div>
    <ul class="side_nav_list">
        <li class="side_nav_list_group" *ngFor="let item of sideNavConfig.items">
            <ul class="side_nav_list">
                <h4 *ngIf="!!item.title">{{item.title}}</h4>
                <li class="side_nav_list_item" *ngFor="let innerItem of item.items">
                    <a class="side_nav_list_item_link" [routerLink]="innerItem.routerLink?.path"
                        routerLinkActive="link-active" [preserveFragment]="innerItem.routerLink?.preserveFragment ?? ''"
                        [queryParamsHandling]="innerItem.routerLink?.queryParamsHandling ?? ''"
                        [ngClass]="{'link-active': innerItem.default && !(activatedRoute.url | async)?.length}">
                        <sxw-svg-icon [name]="innerItem.icon" height="20" width="20" fillColor="#000"></sxw-svg-icon>
                        <span>{{innerItem.label ?? '' | translate}}</span>
                        <small *ngIf="innerItem.soon" class="badge coming-soon" sxwTooltip="Incredible updates are just around the corner!">Soon</small>
                    </a>
                </li>
            </ul>
        </li>
    </ul>
    <app-trustpilot-widget></app-trustpilot-widget>
<!--    <a href="https://www.producthunt.com/posts/chatperk?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-chatperk" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=776775&theme=light&t=1736881472547" alt="ChatPerk - Build&#0032;Smart&#0032;AI&#0032;Assistants&#0032;for&#0032;Your&#0032;Brand | Product Hunt" style="width: 100%; height: 54px;" /></a>-->
    <div class="usage_overview">
      <div class="usage_overview_header">
        <h3 class="plan-name" [style.--visual-color]="subscription?.planSnapshot?.visualColor ?? '#5c5c5c'">
          {{subscription?.planSnapshot?.name ?? 'Starter'}}
        </h3>
        <small
          *ngIf="subscription?.status === eSubscriptionStatus.PastDue"
          class="requiredPaymentBadge badge danger-badge"
          [sxwTooltip]="'sidenav.requiredPaymentTooltip' | translate"
        >
          {{ "sidenav.requiredPaymentBadge" | translate }}
        </small>
      </div>

      <div class="usage_overview_body">

        <div class="usage_overview_item">
          <label>Messages</label>
          <app-progress-bar
            mode="determinate"
            [total]="totalsOverview?.messages ?? 20"
            [completed]="(usageOverviewService.usage$ | async)?.messages ?? 0"
            [thin]="true"
          ></app-progress-bar>
          <div class="usage_numeric_details">{{(usageOverviewService.usage$ | async)?.messages ?? 0}} of {{totalsOverview?.messages ?? 20}}</div>
        </div>
        <div class="usage_overview_item">
          <label>Chat Assistants</label>
          <app-progress-bar
            mode="determinate"
            [total]="totalsOverview?.assistants ?? 1"
            [completed]="(usageOverviewService.usage$ | async)?.assistants ?? 0"
            [thin]="true"
          ></app-progress-bar>
          <div class="usage_numeric_details">{{(usageOverviewService.usage$ | async)?.assistants ?? 0}} of {{totalsOverview?.assistants ?? 1}}</div>
        </div>
        <div class="usage_overview_item">
          <label>Space Members</label>
          <app-progress-bar
            mode="determinate"
            [total]="totalsOverview?.spaceMembers ?? 20"
            [completed]="(usageOverviewService.usage$ | async)?.spaceMembers ?? 0"
            [thin]="true"
          ></app-progress-bar>
          <div class="usage_numeric_details">{{(usageOverviewService.usage$ | async)?.spaceMembers ?? 0}} of {{totalsOverview?.spaceMembers }}</div>
        </div>

      </div>

      <div class="usage_overview_upgrade" [routerLink]="['/', 'space','settings','plans']">
        <button class="select-plan-button" [class.is-popular]="true">
          {{
            (subscription?.status === eSubscriptionStatus.PastDue
              ? "sidenav.completePayment"
              : "sidenav.upgrade"
            ) | translate
          }}
        </button>
      </div>
    </div>
</div>
