import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardLayoutComponent } from 'src/app/layout/dashboard-layout/component/dashboard-layout.component';
import { AddSpaceComponent } from './pages/add-space/add-space.component';
import { accessTokenGuard } from 'src/app/chatperk-core/guards/access-token.guard';
import { EAccessType, EUserType } from 'src/app/chatperk-core/enums';
import { SPACE_FULL_DATASHAPE } from './datashape/space-full-datashape';
import { spaceResolver } from './resolver/space.resolver';
import { AddPaymentMethodComponent } from './pages/add-payment-method/add-payment-method.component';
import { UpdatePaymentMethodComponent } from './pages/update-payment-method/update-payment-method.component';
import { PaymentStatusComponent } from './pages/payment-status/payment-status.component';
import { getAllPaymentMethodsResolver } from 'src/app/applications/dashboard/modules/space/resolver/get-all-payment-methods.resolver';
import { initiatePaymentMethodSetupResolver } from 'src/app/applications/dashboard/modules/space/resolver/initiate-payment-method-setup.resolver';
import { getPaymentMethodResolver } from 'src/app/applications/dashboard/modules/space/resolver/get-payment-method.resolver';
import { getInvoiceResolver } from './resolver/get-invoice.resolver';
import { INVOICE_PAYMENT_STATUS_DATA_SHAPE } from './datashape/invoice.datashape';

const routes: Routes = [
  {
    path: '',
    component: DashboardLayoutComponent,
    data: {sidenav: false},
    children: [
      {
        path: 'add',
        component: AddSpaceComponent,
        canActivate: [accessTokenGuard],
        data: {
          allowedAccessToken: [EAccessType.FAT],
          allowedTypes: [EUserType.Customer],
        },
      },
      {
        path: 'payment-status/:invoiceId',
        component: PaymentStatusComponent,
        runGuardsAndResolvers: 'always',
        data: {
          getInvoiceOptions: { dataShape: INVOICE_PAYMENT_STATUS_DATA_SHAPE },
        },
        resolve: {
          invoice: getInvoiceResolver((route) =>
            route.paramMap.get('invoiceId')
          ),
          paymentMethods: getAllPaymentMethodsResolver,
        },
      },
    ],
  },
  {
    path: 'settings',
    component: DashboardLayoutComponent,
    data: {sidenav: true},
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/update-space/update-space.module').then(
            (m) => m.UpdateSpaceModule
          ),
        resolve: {
          spaceData: spaceResolver,
        },
        canActivate: [accessTokenGuard],
        data: {
          dataShape: SPACE_FULL_DATASHAPE,
        },
      },
      {
        path: 'payment-method/add',
        component: AddPaymentMethodComponent,
        resolve: { clientSecret: initiatePaymentMethodSetupResolver },
        outlet: 'popups',
      },
      {
        path: 'payment-method/view/:id',
        component: UpdatePaymentMethodComponent,
        outlet: 'popups',
        resolve: {
          paymentMethod: getPaymentMethodResolver((route) =>
            route.paramMap.get('id')
          ),
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SpaceRoutingModule {}
