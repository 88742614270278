import { Injectable } from '@angular/core';
import {last, Observable, Subject, tap} from "rxjs";
import { ISpaceType, IUser } from 'src/app/chatperk-core/interfaces';
import {first, map } from "rxjs";
import { GraphService } from 'src/app/kernel/graphql/services/graph.service';
import { FilterInput, FiltersGraphqlInput, ListingGraphqlInput, PaginationGraphqlInput } from 'src/app/kernel/graphql/models';
import { SPACE_BASE_DATASHAPE } from '../datashape/space-base-datashape';
import { GqlResponseInterface } from 'src/app/kernel/graphql/interfaces/gql-response.interface';
import { USER_DATASHAPE } from 'src/app/chatperk-core/data-shape';
import {UsageOverviewService} from "src/app/shared/metrics-shared/services/usage-overview.service";
import { SubscriptionService } from 'src/app/shared/subscription-shared/services/subscription.service';
import {HttpHeaders} from "@angular/common/http";
;

@Injectable({
  providedIn: 'root'
})
export class SpaceService {

  private submitFormSource = new Subject<void>();
  submitForm$ = this.submitFormSource.asObservable();

  constructor(
    private graphService: GraphService,
    private subscriptionService: SubscriptionService,
    private usageOverviewService: UsageOverviewService
  ) {}

  /**
   * get user spaces action
   * @param page
   * @param query
   */
  getUserSpaces(page: number, query: string) {
    const listing = ListingGraphqlInput.wrap(
      new FiltersGraphqlInput([new FilterInput('name', 'LIKE', query)]),
      new PaginationGraphqlInput(page, 5),
    )
    return this.graphService
    .constructListingQuery<{customerSpaces: { data: ISpaceType[] };}>
    (
      [SPACE_BASE_DATASHAPE],
      'customerSpaces',
      listing,
      {listing: 'ListingInput'},
      new HttpHeaders().set("ignore_http_loader", "ignore_http_loader"),
    ).pipe(
      first(),
      map((res) => ({ ...res, data: res.data.customerSpaces?.data }))
    );
  }

 /**
 * change active space action
 * @param target
 */
  changeActiveSpace(spaceId: string) {
    return this.graphService
      .constructMutation<{ changeActiveSpace: IUser }>(
        "changeActiveSpace",
        { activeSpace: "ID" },
        { activeSpace: spaceId },
        [USER_DATASHAPE]
      )
      .pipe(
        last(),
        tap(() => {
          this.subscriptionService.loadActiveSubscription().subscribe();
          this.usageOverviewService.loadUsageOverview().subscribe()
        }),
        map((res) => ({ ...res, data: res.data?.changeActiveSpace }))
      );
  }

  getSpaceById(id: string, dataShape: string): Observable<ISpaceType> {
    return this.graphService.constructQuery<GqlResponseInterface<ISpaceType>>(
      [dataShape],
      'space',
      {id: id}
    ).pipe(map(v => v.data['space']));
  }

  triggerFormSubmission() {
    this.submitFormSource.next();
  }

  deleteSpace(id: string) {
    return this.graphService.constructMutation(
      'deleteSpace',
      {id: 'ID'},
      {id},
      ['code', 'text']
    );
  }

  deleteUserFromSpace(spaceId: string,userId:string){
    return this.graphService.constructMutation(
      'deleteSpaceMember',
      { id: 'ID', userId: 'ID' },
      { id: spaceId, userId: userId },
      ['code', 'text']
    );

  }

  verifyInvitedMember(spaceId: string, secret: string) {
    return this.graphService
      .constructMutation<{ verifyInvitedMember: any }>(
        'verifyInvitedMemberToSpace',
        { id: 'ID', secret: 'String' },
        { id: spaceId, secret: secret },
        ['code', 'text']
      )
      .pipe(first());
  }

}
